class SalaryReportItem {
  constructor ($location, API, ErrFactory, LoaderFactory, Notification, HelpersService) {
    Object.defineProperties(this, {
      $location: { value: $location },
      api: { value: API },
      errFactory: { value: ErrFactory },
      loaderFactory: { value: LoaderFactory },
      notification: { value: Notification },
      helpersService: { value: HelpersService },
    });
  }

  changeLocation(event, to) {
    this.savePagination();
    event.stopPropagation();
    this.$location.path(to);
  }

  getLocalDocTypeName() {
    if (this.salaryReport && this.salaryReport.doc_type && this.salaryReport.doc_type.name_translations) {
      let docTypes = this.salaryReport.doc_type.name_translations;
      let doc = String(docTypes[localStorage.getItem('lang')] || '').trim();
      return doc || String(docTypes['de'] || '').trim() || this.salaryReport.name;
    }
  }

  logoutFromSalaryReports() {
    localStorage.removeItem('salaryReportsTwoFactorToken');
    this.$location.path('/salary-reports-2fa');
  }

  shareSalaryReport(id) {
    this.loaderFactory.show();
    Promise.resolve(this.api.getSalaryReportsById(id))
    .then(res => {
      if (res?.salary_report?.base64pdf) return this.convertDataURIToBinary(res.salary_report.base64pdf);
      return Promise.reject();
    })
    .then(array => PDFJS.getDocument(array).then(() => array))
    .then(pdfAsArray => {
      const blob = new Blob([pdfAsArray], {type: 'application/pdf'});
      let fileName = this.helpersService.normalizeName(this.salaryReport.filename).replace(/ /g, "_");
      if (window.cordova) {
        const FileSystemService = angular.element(document.body).injector().get('FileSystemService');
        return FileSystemService.cleanCacheFolder()
        .then(() => FileSystemService.writeFileToSystem(blob, this.getFileNameWIthExtension(fileName), 'cache'))
        .then(fileEntry => FileSystemService.simpleShareFile(fileEntry))
        .catch((err) => {
          if (err instanceof this.errFactory) err.notify();
          else console.error(err);
        })
        .then(() => {
          this.onSave();
          this.loaderFactory.hide();
        });
      } else this.saveFile(blob, this.getFileNameWIthExtension(fileName));
    })
    .catch((err) => {
      if (err.message && err.message === "Invalid PDF structure") return new this.errFactory.BrokenFile().notify();
      if (!(err instanceof this.errFactory.BrokenFile)) this.logoutFromSalaryReports();
      if (err.response && err.response.status && err.response.status === 403) console.error(err);
      else if (err instanceof this.errFactory) err.notify();
      else console.error(err);
    })
    .then(() => this.loaderFactory.hide());
  }

  convertDataURIToBinary(base64) {
    let base = base64.replace('data:application/pdf;', '').replace('base64,', '');
    let raw;
    try { raw = window.atob(base); }
    catch (err) { throw new this.errFactory.BrokenFile(); }
    let rawLength = raw.length;
    let array = new Uint8Array(new ArrayBuffer(rawLength));

    for(let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }

    return array;
  }

  getFileNameWIthExtension(fileName) {
    if (fileName.toLowerCase().includes('.pdf')) return fileName;
    else return fileName + '.pdf';
  }

  saveFile(blob, name) {
    var a = document.createElement('a');
    a.download = name;

    var url = URL.createObjectURL(blob);
    a.href = url;
    a.onload = function () {
      URL.revokeObjectURL(url);
    }

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.onSave();
    this.loaderFactory.hide();
  }

}

app.component('salaryReportItem', {
  template: require('scripts/components/salary-reports/sr-list/salary-report-item/salary-report-item.html'),
  controller: SalaryReportItem,
  bindings: {
    salaryReport:   '<',
    savePagination: '&',
    onSave:         '&',
    unread:         '='
  }

});
