class SalartReportsRoot {
  $scope:        any;
  $rootScope:    any;
  pnListService: any;

  unreadCount:   number;
  constructor($scope: any, $rootScope: any, PNListService: any) {
    Object.defineProperties(this, {
      $scope:        { value: $scope        },
      $rootScope:    { value: $rootScope    },
      pnListService: { value: PNListService },
    });

    this.loadUnreadSalaryReports();
    this.addWatcher();
  }

  private addWatcher(): void {
    this.$rootScope.$on('unreadReload', () => setTimeout(() => this.loadUnreadSalaryReports()));
  }

  private loadUnreadSalaryReports(): Promise<any> {
    return this.pnListService.getOwn(['salary_report'])
    .then(pn => this.unreadCount = pn.length)
    .then(() => this.$scope.$apply());
  }

}

window.app.component('srRoot', {
  template: require('scripts/components/salary-reports/sr-root/sr-root.html'),
  controller: ['$scope', '$rootScope', 'PNListService', SalartReportsRoot]
});
