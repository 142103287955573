class SalaryReportsTwoFactorAuthentication {
  constructor ($scope, $rootScope, $window, $location, API, AuthService, ErrFactory, LoaderFactory) {
    Object.defineProperties(this, {
      $scope:        { value: $scope        },
      $rootScope:    { value: $rootScope    },
      $window:       { value: $window       },
      $location:     { value: $location     },
      api:           { value: API           },
      errFactory:    { value: ErrFactory    },
      loaderFactory: { value: LoaderFactory }
  });
    this.day   = null;
    this.month = null;
    this.year  = null;
    this.currentUser  = AuthService.authorizedUser;
    let publishingDay = this.currentUser?.location?.salary_reports_publishing_day_of_month;
    if (publishingDay && new Date().getDate() > publishingDay-8 && new Date().getDate() < publishingDay+1) this.publishingDay = publishingDay;

    this.checkToken();

    document.querySelectorAll(".birth-input").forEach(el => el.addEventListener("keypress", function (evt) {
      if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57) evt.preventDefault();
    }));
  }

  checkToken() {
    let srTwoFactor = localStorage.getItem('salaryReportsTwoFactorToken');
    if (srTwoFactor) this.$location.path('/salary-reports-list');
    else {
      localStorage.removeItem('salaryReportsTwoFactorToken');
      this.prepareSalaryReports();
    }
  }

  prepareSalaryReports() {
    this.loaderFactory.show();
    Promise.resolve(this.api.requestSalaryReports2FA())
    .then(() => this.apiCallsDone = true)
    .then(() => this.$scope.$apply())
    .catch((e) => { e instanceof this.errFactory ? e.notify() : console.error(e) });
  }

  nextInput(e) {
    if (this.activeInput === e.target.id && isFinite(e.key) && e.key !== ' ') {
      if (+e.target.value > +e.target.max) e.target.value = e.target.max;
      if (e.target.value.length > 1 && e.target.nextElementSibling) e.target.nextElementSibling.focus();
    }
    e.target.value = e.target.value.replace(/[^0-9]/g,'');
  }

  keyDown(e) {
    this.activeInput = e.target.id;
    if (e.key === 'Backspace' || e.key === 'Delete') {
      e.preventDefault();
      if (e.target.value) this.cleanField(e);
      else if (e.target.previousElementSibling) e.target.previousElementSibling.focus();
      return false;
    }
    else if (isFinite(e.key) && this[e.target.id]) this.cleanField(e);
    else if (!isFinite(e.key) || e.key === ' ') return false;
  }

  saveTemp(e) {
    this[e.target.id] = e.target.value;
  }

  cleanField(e) {
    this[e.target.id.split('-')[1]] = null;
    this[e.target.id] = null;
  }

  getMaxYear() {
    let noKidsAllowed = 16;
    return new Date().getFullYear() - noKidsAllowed;
  }

  confirm() {
    this.loaderFactory.show();
    this.api.createSalaryReportsToken(`${this.formatExtraZero(this.day)}${this.formatExtraZero(this.month)}${this.year}`)
    .then((token) => {
      if (token) localStorage.setItem('salaryReportsTwoFactorToken', token);
      if (this.$rootScope.salaryReportId) this.$location.path(`/salary-reports/details/${this.$rootScope.salaryReportId}`);
      else this.$location.path('/salary-reports-list');
    })
    .catch((err) => {
      if (err instanceof this.errFactory) err.notify();
      else console.error(err);
    })
    .then(() => this.loaderFactory.hide());
  }

  formatExtraZero(num) {
    return num > 9 ? num : '0' + num;
  }

  getSupportNumber() {
    return this.currentUser && this.currentUser.location && this.currentUser.location.phone ||
           '+49 699 0501 4320'; // Tempton Digital (Support)
  }

  call(phone) {
    let tel;
    let num = phone.replace(/ /g, '').replace(/-/g, '');
    if (num.charAt(0) === '0') tel = `+49${num.substring(1)}`;
    else if (num.substring(0,2) === '49' && num.length === 12) tel = `+${num}`;
    else if (num.substring(0,3) === '+49') tel = `${num}`;
    else tel = `+49${num}`;

    let link = `tel:${tel}`;
    return this.$window.open(link, '_system');
  }

}

app.component('salaryReportsTwoFactorAuthentication', {
  template: require('scripts/components/salary-reports/sr-2fa/sr-2fa.html'),
  controller: SalaryReportsTwoFactorAuthentication
});
