class SmallPopup {
  constructor (PopupRegisterService, $element) {
    const popapResterItem = new PopupRegisterService.PopupRegisterItem({
        close: () => this.hide(),
        skipDefaultAction: true
    });

    PopupRegisterService.attach(popapResterItem);

    $element.on("$destroy", () => {
      PopupRegisterService.detach(popapResterItem);
    });
  }

  hide () {
    this.onHide();
  }

  skipHide (event) {
    event.stopPropagation();
  }
}

app.component('smallPopup', {
    template: require('scripts/components/small-popup/small-popup.html'),
    controller: SmallPopup,
    transclude: true,
    bindings : {
      onHide: '&'
    }
});
