class SelectPopup {
    constructor (PopupRegisterService, $element) {
        const popapResterItem = new PopupRegisterService.PopupRegisterItem({
                close: () => this.hide(),
            skipDefaultAction: true
    });

        PopupRegisterService.attach(popapResterItem);

        $element.on("$destroy", () => {
            PopupRegisterService.detach(popapResterItem);
    });
    }

    hide () {
        this.onHide();
    }
}

app.component('selectPopup', {
    template: require('scripts/components/select-popup/select-popup.html'),
    controller: SelectPopup,
    transclude: true,
    bindings : {
        onHide: '&'
    }
});
