class SalaryReportsList {
  constructor ($scope, $rootScope, $location, API, ErrFactory, LoaderFactory, PNListService) {
    Object.defineProperties(this, {
      $scope:        { value: $scope        },
      $rootScope:    { value: $rootScope    },
      $location:     { value: $location     },
      api:           { value: API           },
      errFactory:    { value: ErrFactory    },
      loaderFactory: { value: LoaderFactory },
      pnListService: { value: PNListService }
    });

    this.totalPages = 0;
    this.page       = 1;

    this.column         = 'created_at';
    this.$scope.reverse = false;

    this.loadSalaryReports();
    this.loadUnreadSalaryReports();
  }

  logoutFromSalaryReports() {
    localStorage.removeItem('salaryReportsTwoFactorToken');
    this.$location.path('/salary-reports-2fa');
    this.$scope.$apply();
  }

  loadUnreadSalaryReports() {
    return this.pnListService.getOwn(['salary_report'])
    .then(pn => this.unreadReports = pn)
    .then(() => this.$scope.$apply());
  }

  loadSalaryReports(page) {
    this.loaderFactory.show();
    this.preparePagination();
    if (!page) page = this.page;
    return this.api.getSalaryReportsPaginated(page, this.column, this.$scope.reverse)
    .then((res) => {
      this.totalPages = res.meta.paging.total_pages;
      this.page = page;
      this.salaryReports = res.salary_reports;
      this.reportsLoaded = true;
      this.$scope.$apply();
    })
    .catch((err) => {
      this.logoutFromSalaryReports();
      if (err.response && err.response.status && err.response.status === 403) console.error(err);
      else if (err instanceof this.errFactory) err.notify();
      else console.error(err);
    })
    .then(() => this.loaderFactory.hide());
  }

  preparePagination() {
    if (this.$rootScope.paginationPage) {
      this.page = this.$rootScope.paginationPage;
      this.column = this.$rootScope.paginationColumn;
      this.$scope.reverse = this.$rootScope.paginationReverse;
      if (this.$scope.reverse) {
        if (this.column === 'date') this.$scope.reverseDate = true;
        else if (this.column === 'doc_type_name') this.$scope.reverseDocumentType = true;
      }
      this.$rootScope.paginationPage = null;
      this.$rootScope.paginationColumn = null;
      this.$rootScope.paginationReverse = null;
    } else {
      this.page = this.page || 1;
      this.column = this.column || 'created_at';
      this.$scope.reverse = this.$scope.reverse || false;
    }
  }

  savePagination() {
    this.$rootScope.paginationPage    = this.page;
    this.$rootScope.paginationColumn  = this.column;
    this.$rootScope.paginationReverse = this.$scope.reverse;
  }

  sort(col) {
    if (col === 'date') {
      this.column = 'created_at';
      this.$scope.reverseDate = !this.$scope.reverseDate;
    } else if (col === 'doc_type_name') {
      this.column = 'doc_type_name';
      this.$scope.reverseDocumentType = !this.$scope.reverseDocumentType;
    }
    this.$scope.reverse = !this.$scope.reverse;
    this.loadSalaryReports();
  }

  isUnread(id) {
    return this.unreadReports.find(r => r.trigger.source_id === id);
  }

  markAsRead(id) {
    let unread = this.unreadReports.find(r => r.trigger.source_id === id);
    if (unread?.id) {
      return this.pnListService.markAsRead(unread.id)
      .then(() => this.unreadReports = this.unreadReports.filter(r => r.id !== unread.id))
      .then(() => this.$scope.$apply());
    } else return Promise.resolve();
  }

}

app.component('salaryReportsList', {
  template: require('scripts/components/salary-reports/sr-list/salary-reports-list.html'),
  controller: SalaryReportsList
});
