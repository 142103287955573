class Releases {
  $scope:          any;
  $timeout:        any;
  $location:       any;
  api:             any;
  authService:     any;
  appFactory:      any;
  databaseService: any;
  errFactory:      any;
  loaderFactory:   any;
  releasesFactory: any;
  releasesService: any;

  column:  any;
  reverse: any;

  page:              number   = 1;
  totalCount:        number   = 0;
  paginationButtons: number[] = [];

  releases:      any[] = [];
  localReleases: any[] = [];

  constructor($scope: any, $timeout: any, $location: any, API: any, AuthService: any, AppFactory: any, DatabaseService: any, ErrFactory: any, LoaderFactory: any, ReleasesFactory: any, ReleasesService: any) {
    Object.defineProperties(this, {
      $scope:          { value: $scope          },
      $timeout:        { value: $timeout        },
      $location:       { value: $location       },
      api:             { value: API             },
      authService:     { value: AuthService     },
      appFactory:      { value: AppFactory      },
      databaseService: { value: DatabaseService },
      errFactory:      { value: ErrFactory      },
      loaderFactory:   { value: LoaderFactory   },
      releasesFactory: { value: ReleasesFactory },
      releasesService: { value: ReleasesService }
    });

    this.loadReleases();
  }

  private loadReleases(): void {
    Promise.resolve()
    .then(() => {
      if (this.appFactory.isNetwork()) this.refreshReleases();
      else this.loadLocalReleases();
    });
  }

  paginationCallback(page: number): void {
    this.page = page;
    this.loadReleases();
  }

  private refreshReleases(page = null) {
    this.loaderFactory.show();
    if (!page) page = this.page;
    this.releasesService.getReleases(page)
    .then(res => {
      this.page = page;
      this.totalCount = res.meta.paging.total_pages;

      this.releases = res.releases.map(r => new this.releasesFactory(r));

      this.$scope.$apply();
      return Promise.all(this.releases.map((data) => data.save()));
    })
    .catch((err) => console.error(err))
		.then(() => this.loaderFactory.hide());
  }

  private loadLocalReleases(): void {
    this.loaderFactory.show();
    this.databaseService.getLocalPagination('releases', this.page, this.column, this.reverse)
    .then(res => {
      if (res) {
        this.localReleases = res.data.map(r => new this.releasesFactory(r));
        this.releases      = res.sortedData.map(r => new this.releasesFactory(r));
        this.page          = res.page;
        this.totalCount    = res.totalCount;

        this.$scope.$apply();
      } else this.$location.path('/projects');
    })
    .catch(err => console.error(err))
    .then(() => this.loaderFactory.hide());
  }

}

window.app.component('releases', {
  template: require('scripts/components/releases/releases.html'),
  controller: ['$scope', '$timeout', '$location', 'API', 'AuthService', 'AppFactory', 'DatabaseService', 'ErrFactory', 'LoaderFactory', 'ReleasesFactory', 'ReleasesService', Releases]
});
