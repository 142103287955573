class SalaryReportDetails {
  constructor ($scope, $rootScope, $routeParams, $location, API, ErrFactory, LoaderFactory, HelpersService, PNListService ) {
    Object.defineProperties(this, {
      $scope:         { value: $scope         },
      $rootScope:     { value: $rootScope     },
      $routeParams:   { value: $routeParams   },
      $location:      { value: $location      },
      api:            { value: API            },
      errFactory:     { value: ErrFactory     },
      loaderFactory:  { value: LoaderFactory  },
      helpersService: { value: HelpersService },
      pnListService:  { value: PNListService  }
    });

    this.$rootScope.salaryReportId = null;
    this.loadSalaryReportById();
  }

  loadSalaryReportById() {
    this.loaderFactory.show();
    let id = parseInt(this.$routeParams.id);
    Promise.resolve(this.api.getSalaryReportsById(id))
    .then(res => {
      if (res && res.salary_report) {
        this.salaryReport = res.salary_report;
        return this.loadPDF();
      } else this.$location.path('/salary-reports-list');
    })
    .catch((err) => {
      if (err.message && err.message === "Invalid PDF structure") {
        this.$location.path('/salary-reports-list');
        return new this.errFactory.BrokenFile().notify();
      }
      this.$rootScope.salaryReportId = this.$routeParams.id;
      this.logoutFromSalaryReports();
      if (err.response && err.response.status && err.response.status === 403) console.error(err);
      else if (err instanceof this.errFactory) err.notify();
      else console.error(err);
    })
    .then(() => this.loaderFactory.hide());
  }

  loadPDF() {
    this.pdfAsArray = this.convertDataURIToBinary(this.salaryReport.base64pdf);
    if (!this.pdfAsArray) {
      (new this.errFactory.BrokenFile()).notify();
      this.$location.path('/salary-reports-list');
    }
    else return PDFJS.getDocument(this.pdfAsArray)
    .then(pdf => this.pdf = pdf)
    .then(() => this.renderPage(1))
    .then(() => this.markAsRead());
  }

  renderPage(pageNum) {
    return Promise.resolve()
    .then(() => this.pdf.getPage(pageNum))
    .then((page) => {
      this.page = pageNum;

      let scale = 3;
      let viewport = page.getViewport(scale);
      let canvas   = document.getElementById('my-canvas');
      let context  = canvas.getContext('2d');

      canvas.height = viewport.height;
      canvas.width  = viewport.width;

      return page.render({ canvasContext: context, viewport: viewport }).then(() => canvas);
    })
    .then((canvas) => {
      this.image = canvas.toDataURL('image/png');
      this.$scope.$apply();
      return canvas;
    })
    .then((canvas) => this.prepareImage(canvas));
  }

  logoutFromSalaryReports() {
    localStorage.removeItem('salaryReportsTwoFactorToken');
    this.$location.path('/salary-reports-2fa');
  }

  convertDataURIToBinary(dataURI) {
    let base64 = dataURI.replace('data:application/pdf;', '').replace('base64,', '');
    let raw;
    try { raw = window.atob(base64); }
    catch (err) { return false; }
    let rawLength = raw.length;
    let array = new Uint8Array(new ArrayBuffer(rawLength));

    for(let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }

    return array;
  }

  prepareImage(canvas) {
    let content = document.getElementById('salary-report-details');
    let height  = content.offsetHeight - 130;
    let width   = content.offsetWidth  - 42;

    let container = document.getElementsByClassName('pinch-zoom-container')[0];
    let image     = document.getElementById('pinch-zoom-image-id');

    container.style.height = `${height}px`;
    container.style.width  = `${width}px`;

    if (canvas.height > height || canvas.width > width) {
      if (canvas.height > canvas.width) {
        width  = Math.min(canvas.width  / (canvas.height / height), width);
        height = width * (canvas.height / canvas.width);
      }
      else {
        height = Math.min(canvas.height / (canvas.width / width), height);
        width  = height * (canvas.width / canvas.height);
      }
    }
    image.style.minHeight = `${height}px`;
    image.style.height    = `${height}px`;
    image.style.minWidth  = `${width}px`;   
    image.style.width     = `${width}px`;
  }

  shareFile() {
    this.loaderFactory.show();
    const blob = new Blob([this.pdfAsArray], {type: 'application/pdf'});
    let fileName = this.helpersService.normalizeName(this.salaryReport.filename).replace(/ /g, "_");
    if (window.cordova) {
      const FileSystemService = angular.element(document.body).injector().get('FileSystemService');
      return FileSystemService.cleanCacheFolder()
      .then(() => FileSystemService.writeFileToSystem(blob, this.getFileNameWIthExtension(fileName), 'cache'))
      .then(fileEntry => FileSystemService.simpleShareFile(fileEntry))
      .catch((err) => {
        if (err instanceof this.errFactory) err.notify();
        else console.error(err);
      })
      .then(() => {
        this.loaderFactory.hide();
      });
    } else this.saveFile(blob, this.getFileNameWIthExtension(fileName));
  }

  getFileNameWIthExtension(fileName) {
    if (fileName.includes('.pdf')) return fileName;
    else return fileName + '.pdf';
  }

  saveFile(blob, name) {
    var a = document.createElement('a');
    a.download = name;

    var url = URL.createObjectURL(blob);
    a.href = url;
    a.onload = function () {
      URL.revokeObjectURL(url);
    }

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.loaderFactory.hide();
  }

  markAsRead() {
    return this.pnListService.getOwn(['salary_report'])
    .then(pn => pn.find(ur => ur.trigger.source_id === this.salaryReport.id))
    .then(pn => { if (pn) this.pnListService.markAsRead(pn.id) });
  }

}

app.component('salaryReportDetails', {
  template: require('scripts/components/salary-reports/sr-details/salary-report-details.html'),
  controller: SalaryReportDetails
});
