class ReferAFriend {
  constructor($scope, $location, API, ErrFactory, Notification, LoaderFactory) {
    Object.defineProperties(this, {
      $scope:        { value: $scope        },
      $location:     { value: $location     },
      api:           { value: API           },
      errFactory:    { value: ErrFactory    },
      notification:  { value: Notification  },
      loaderFactory: { value: LoaderFactory }
    });

    this.salutation = "mr";
    this.phoneNumberPattern = "^[\\d\\s\\+\\-\\(\\)]+$";
    this.loading = false;
  }

  submitForm() {
    let self = this;
    this.loading = true;
    this.loaderFactory.show();
    let params = {
      referral_program_referee: {
        salutation: this.salutation,
        first_name: this.firstName.trim(),
        last_name: this.lastName.trim(),
        mobile_phone: this.mobilePhone.trim(),
        email: this.email ? this.email.trim() : null
      }
    };
    return Promise
      .resolve(this.api.createReferralProgramReferee(params))
      .then(res => this.loaderFactory.hide())
      .then(res => this.notification.alert({
        title: 'refer-a-friend.notification.title',
        desc: 'refer-a-friend.notification.description'
        }, () => self.$scope.$apply(() => self.$location.path(`/projects`))
      ))
      .catch(e => {
        this.loaderFactory.hide();
        if (e instanceof this.errFactory) e.notify(() => this.loading = false);
        else {
          console.error(e);
          this.loading = false;
        }
      });
  }
}

app.component('referAFriend', {
  template: require('scripts/components/refer-a-friend/refer-a-friend.html'),
  controller: ReferAFriend
});
