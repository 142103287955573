class SingleSelectPopup {
  title:      string;
  popupTitle: string;
  model:      any;
  options:    any[];
  disabled:   boolean;
  onChange:   Function;

  showPopup:  boolean;
  constructor() { }

  showSelect(): void {
    this.showPopup = true;
  }

  hide(e: any = null): void {
    if (!e || !e.target.closest('.options-popup')) this.showPopup = false;
  }

  select(option: any) {
    this.model = option;
    this.onChange({ option });
    this.hide();
  }

  isActive(option): boolean {
    return this.model?.value === option.value;
  }

}

window.app.component('singleSelectPopup', {
  template: require('scripts/components/single-select-popup/single-select-popup.html'),
  controller: [SingleSelectPopup],
  bindings: {
    title:      '=',
    popupTitle: '=',
    model:      '<',
    options:    '=',
    disabled:   '=',
    onChange:   '&'
  }
});
